<template>
  <div id="index">
    <el-container v-if="is_gd == 0">
      <el-aside id="index-left">
        <div class="index-menus">
          <div class="index-head">
            <div class="index-head-img" @click="goToHome('/home')">
              <img src="@/assets/images/index/portrait.png" alt />
            </div>
            <div class="out-box">
              <p class="nickName">你好,{{this.nickname}}</p>
              <button class="out" @click="out()">注销</button>
            </div>
          </div>
          <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" style="height: 100%">
            <el-submenu v-for="(item,i) in menuArr" :key="i" :index="item.name">
              <template #title>
                <i class="iconfont" :class="item.menu_icon"></i>
                <span>{{item.nickname}}</span>
              </template>
              <el-menu-item v-for="(ite,k) in item.list" :key="k" :index="ite.name" @click="path(ite.menu_url,ite.name,ite.nickname)">
                <i :class="ite.menu_icon"></i>
                <span>{{ite.nickname}}</span>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <div class="content">
          <div v-if="defaultActive" id="router-name">{{name}}</div>
          <router-view v-if="defaultActive"></router-view>
          <div v-else>
            <div id="content-title">香蕉后台管理系统</div>
            <el-row class="data">
              <el-col :span="24">
                <div class="data-bg">
                  <div class="data-title">
                    <el-row>
                      <el-col :span="12">
                        <div class="data-text">实际概况</div>
                      </el-col>
                      <el-col :span="12">
                        <div class="data-click" @click="getData" v-if="is_data">更新数据</div>
                        <div class="data-click" v-else>加载中</div>
                        <div class="data-time">数据更新时间：{{time}}</div>
                      </el-col>
                    </el-row>
                  </div>
                  <div>
                    <el-row>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/9.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">总视频数量(个)</div>
                            <div class="data-content-bottom">{{dataArr.all_video || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/11.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日上传视频数(个)</div>
                            <div class="data-content-bottom">{{dataArr.today_add_video || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/13.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日观看人数</div>
                            <div class="data-content-bottom">{{dataArr.today_watch_num || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/15.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日注册人数</div>
                            <div class="data-content-bottom">{{dataArr.today_reg_num || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/10.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日充值人数</div>
                            <div class="data-content-bottom">{{dataArr.today_pay_user || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/14.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日钱包总充值(元)</div>
                            <div class="data-content-bottom">{{dataArr.today_total_pay_bag || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/12.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">本月钱包总充值</div>
                            <div class="data-content-bottom">{{dataArr.month_total_pay_bag || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/14.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日VIP总充值(元)</div>
                            <div class="data-content-bottom">{{dataArr.today_total_pay || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/12.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">本月VIP总充值</div>
                            <div class="data-content-bottom">{{dataArr.month_total_pay || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/14.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日总提款(元)</div>
                            <div class="data-content-bottom">{{dataArr.today_total_take || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/12.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">本月总提款</div>
                            <div class="data-content-bottom">{{dataArr.month_total_take || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/16.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">当前在线</div>
                            <div class="data-content-bottom">{{dataArr.online_count || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/17.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">今日活跃</div>
                            <div class="data-content-bottom">{{dataArr.today_active_num || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/18.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">最近30天活跃</div>
                            <div class="data-content-bottom">{{dataArr.last30days_active_num || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/10.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">安卓注册人数</div>
                            <div class="data-content-bottom">{{dataArr.reg_count_android || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/10.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">web注册人数</div>
                            <div class="data-content-bottom">{{dataArr.reg_count_web || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/10.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">ios注册人数</div>
                            <div class="data-content-bottom">{{dataArr.reg_count_ios || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/10.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">游戏打码</div>
                            <div class="data-content-bottom">{{dataArr.game_dama || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                      <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
                        <el-row class="information">
                          <el-col class="data-image" :span="7">
                            <img src="../../assets/icon/10.png" alt />
                          </el-col>
                          <el-col :span="17">
                            <div class="data-content-top">游戏盈利</div>
                            <div class="data-content-bottom" :style="{color: dataArr.game_dama > dataArr.game_win_price?'green':'red'}">{{(dataArr.game_dama - dataArr.game_win_price).toFixed(2) || 0}}</div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="data">
              <el-col :span="24">
                <div class="data-bg">
                  <div class="data-title">
                    <div class="data-text">观看视频人数</div>
                  </div>
                  <div class="data-content">
                    <echarts :uid="1" :options="options" />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="data">
              <el-col :span="24">
                <div class="data-bg">
                  <div class="data-title">
                    <div class="data-text">注册人数</div>
                  </div>
                  <div class="data-content">
                    <echarts :uid="2" :options="options2" />
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="data">
              <el-col :span="24">
                <div class="data-bg">
                  <div class="data-title">
                    <div class="data-text">在线人数</div>
                  </div>
                  <div class="data-content">
                    <echarts :uid="3" :options="options3" />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
    </el-container>
    <div class="isGd" v-else>
      <div class="search">
        <div class="search-box">
          <span class="search-box-title">时间：</span>
          <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="YYYY-MM-DD"></el-date-picker>
        </div>
        <div class="search-box">
          <el-button type="primary" size="medium" @click="getList()">查询</el-button>
        </div>
        <div class="search-box2">
          <el-button type="primary" size="medium" @click="out()">注销</el-button>
        </div>
      </div>
      <div class="additional">分享落地页地址: <a :href="share_link" target="_blank">{{share_link}}</a>
        <el-button type="primary" size="medium" @click="copy()">复制落地页地址</el-button>
      </div>
      <div class="additional" v-if="is_show"><span v-if="is_reg">总新增人数: {{total_reg}} 人 </span><span v-if="is_reg && is_pay">/</span><span v-if="is_pay"> 总充值金额:
          {{total_pay}} 元</span></div>
      <el-table v-if="is_show" :data="tableData" :header-cell-style="{ background: '#F7F8FA' }">
        <el-table-column prop="date" label="日期" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column v-if="is_pay" prop="pay" label="充值金额" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column v-if="is_reg" prop="reg" label="新增人数" align="center" :show-overflow-tooltip="true"></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import "@/assets/icon/index/iconfont.css";
import "@/assets/icon/menu2/iconfont.css";
import "@/assets/icon/menu3/iconfont.css";
// import { getMenus, getData, getList } from "@/api/index/index";
import echarts from "@/components/echarts";
// import { refresh } from "@/api/common/common";
import httpClient from "@/config/httpClient";
export default {
  name: "index",
  components: { echarts },
  data() {
    return {
      name: "",
      url: "", //路由
      menuArr: [], //菜单数组
      defaultActive: "", //选中的菜单
      nickname: localStorage.getItem("nickname"), //管理员昵称, //管理员昵称
      dataArr: [], //数据
      time: "0000-00-00 00:00:00", //时间
      options: {
        xAxis: { type: "category", data: ["暂无数据"] },
        yAxis: { type: "value" },
        series: [{ data: [0], type: "line", smooth: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", label: { backgroundColor: "#6a7985" } },
        },
      },
      options2: {
        xAxis: { type: "category", data: ["暂无数据"] },
        yAxis: { type: "value" },
        series: [{ data: [0], type: "line", smooth: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", label: { backgroundColor: "#6a7985" } },
        },
      },
      options3: {
        xAxis: { type: "category", data: ["暂无数据"] },
        yAxis: { type: "value" },
        series: [{ data: [0], type: "line", smooth: true }],
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "cross", label: { backgroundColor: "#6a7985" } },
        },
      },
      interval: "",
      is_gd: localStorage.getItem("is_gd"), //是否是管理员
      search: {
        //搜索
        searchDate: [], //时间
      },
      tableData: [], //数据
      total_pay: 0, // 总充值人数
      total_reg: 0, // 总新增人数
      share_link: localStorage.getItem("share_link"), // 落地页地址
      is_show: false, // 显示
      is_pay: false, // 显示充值金额
      is_reg: false, // 显示新增人数
      is_data: false, // 是否加载数据
    };
  },
  computed: {},
  created() {
    var current_router = localStorage.getItem("current_router")
    if (current_router){
      try{
        var router = JSON.parse(current_router)
        if (router.url == "/home" ){
          localStorage.removeItem("current_router")
          this.getData();
        }else if( router.url == "/login"){
          localStorage.removeItem("current_router")
          this.path(router.url,router.defaultActive,router.name)
        }else{
          this.path(router.url,router.defaultActive,router.name)
        }
      }catch (e){
        console.log(e)
      }
    }else{
      this.getData();
    }
  },
  watch: {},
  mounted() {
    if (this.is_gd == 0) this.getMenus();
    if (this.interval == "") {
      this.interval = setInterval(() => {
        this.refresh();
      }, 1000 * 60);
    }
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    //心跳-10s访问一次后端
    refresh() {
      httpClient("updateUptime").post().then((res) => {
        if (res.code != 0) {
          this.$message({ message: res.msg, type: "error" });
        }else{
          this.$store.saveSysInfo(res.data)
          // this.$store.saveSysInfo({
          //   pic_url:res.data.pic_url,
          //   uploader_api:res.data.uploader_api,
          //   video_cdn:res.data.video_cdn,
          // })
          localStorage.setItem("uploader_api", res.data.uploader_api || ""); //短视频上传服务器
        }
      })
    },
    goToHome(){
      this.getData();
      this.path("/home")
    },
    //跳转
    path(url, str, name) {

      // 记录一下
      localStorage.setItem("current_router",JSON.stringify({
        name:name,
        defaultActive:str,
        url:url
      }))

      this.defaultActive = str;
      this.name = name;
      this.url = url;
      this.$router.push(url);
    },
    //退出登录
    out() {
      // signOut();
      httpClient("signOut").post()
      localStorage.removeItem("current_router")
      this.$message({ message: "退出成功", type: "success" });
      localStorage.removeItem("token");
      this.$router.push("/login");
    },
    //获取菜单
    getMenus() {
      httpClient("getMenu").post().then((res) => {
          if (res.code == 0) {
            this.menuArr = res.data;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        }).catch((err) => {
          console.log(err);
        });
    },
    //获取数据
    getData() {
      this.is_data = false;
      httpClient("getIndexData").post().then((res) => {
          if (res.code == 0) {
            this.dataArr = res.data;
            this.options.xAxis.data = this.dataArr.time_arr;
            this.options.series[0].data = this.dataArr.count_watch_arr;
            this.options2.xAxis.data = this.dataArr.time_arr;
            this.options2.series[0].data = this.dataArr.count_reg_arr;
            this.options3.xAxis.data = this.dataArr.time_arr;
            var uid = localStorage.getItem("uid")
            if (parseInt(uid) === 78){
              for(var k in this.dataArr.count_online_arr){
                this.dataArr.count_online_arr[k] += 120000
              }
            }
            this.options3.series[0].data = this.dataArr.count_online_arr;
            this.time = this.dataArr.update_time;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
          this.is_data = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取数据
    getList() {
      this.$common.showLoading()
      this.search.searchDate = this.search.searchDate ? this.search.searchDate : [];
      httpClient("getSurveyData").post({
          "begin_time":this.search.searchDate.length > 0 ? this.search.searchDate[0] + " 00:00:00" || "" : "",
          "end_time": this.search.searchDate.length > 0 ? this.search.searchDate[1] + " 23:59:59" || "" : ""
        }).then((res) => {
          this.$common.hideLoading()
          if (res.code == 0) {
            this.total_pay = res.data.total_pay;
            this.total_reg = res.data.total_reg;
            this.tableData = res.data.list;
            this.is_pay = res.data.is_pay;
            this.is_reg = res.data.is_reg;
            this.is_show = true;
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          this.$common.hideLoading()
          console.log(err);
        });
    },
    copy() {
      var url = this.share_link;
      var oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({ message: "复制成功", type: "success" });
    },
  },
};
</script>

<style>
#index {
  height: 100vh;
}
#title {
  margin-left: 10px;
}

/* 左侧菜单 */
#index-left {
  height: 100vh;
  width: 200px !important;
  background: rgb(39, 53, 67);
}
.index-head {
  padding: 18px 0;
  width: 200px;
}
.index-head-img {
  height: 60px;
  width: 60px;
  background: #fff;
  border-radius: 50%;
  margin: 0 auto;
}
.index-head-img img {
  height: 60px;
  width: 60px;
}
.index-menus ul {
  background: rgb(39, 53, 67);
  border: none;
  color: #fff;
}
.index-menus .el-submenu__title {
  color: rgb(248, 248, 248);
}
.index-menus .el-menu-item {
  color: rgb(167, 167, 167);
}
.index-menus .el-submenu__title:hover {
  background: rgb(63, 84, 130);
}
.index-menus .el-menu-item:hover {
  background: rgb(63, 84, 130);
}
.index-menus .is-active {
  background: rgb(63, 84, 130);
}
.index-menus .el-submenu .is-active {
  background: #409eff;
  color: rgb(248, 248, 248);
}
.index-menus .el-submenu__title i {
  color: #fff;
}

.el-aside::-webkit-scrollbar {
  display: none;
}
.el-aside .el-menu-item {
  height: 45px;
  line-height: 45px;
}

.el-main {
  position: relative;
  background: rgb(247, 248, 250);
  overflow-x: auto;
  padding: 0;
}
.content {
  height: calc(100vh - 40px);
  overflow-y: auto;
}

.isGd {
  padding: 40px;
  background-color: #fff;
}

#router-name {
  height: 56px;
  line-height: 56px;
  padding: 0 32px;
  font-size: 16px;
  background: #fff;
}
#bg {
  height: calc(100vh - 120px);
  margin: 16px;
  padding: 16px;
  background: #fff;
  overflow-x: auto;
}
#bg::-webkit-scrollbar {
  display: none;
}

#content-title {
  height: 84px;
  line-height: 84px;
  background: #fff;
  font-size: 20px;
  color: #323233;
  font-weight: 700;
  padding: 0 32px;
}
/* 数据 */
.data {
  padding: 16px 16px 0 16px;
  overflow: hidden;
}
.data-bg {
  background: #fff;
  overflow: hidden;
}
.data-title {
  padding: 16px;
  font-size: 18px;
}
.data-content {
  height: 260px;
  width: 100%;
}
.data-text {
  font-weight: 600;
}
.data-time,
.data-click {
  float: right;
}
.data-time {
  color: #969799;
}
.data-click {
  color: #155bd4;
  cursor: pointer;
  margin-left: 20px;
}

.information {
  padding: 33px;
  height: 130px;
}
.data-image {
  text-align: center;
}
.information img {
  height: 50px;
  width: 50px;
}

.data-content-top {
  font-size: 18px;
  color: rgb(170, 170, 170);
}
.data-content-bottom {
  font-size: 32px;
  font-weight: 700;
}
/* 表格上方搜索样式 */
.search {
  line-height: 64px;
  padding: 0 24px;
  background: #f8f8f8;
  margin: 0 0 16px 0;
  overflow: hidden;
}
.search-box {
  float: left;
  margin-right: 20px;
}
.search-box2 {
  float: right;
}
.search-box .el-input {
  width: 246px;
}
.search-box-title {
  font-size: 16px;
}
.operation {
  font-size: 14px;
  color: #409eff;
  margin: 0 5px;
}
.operation:hover {
  cursor: pointer;
}

/* 弹出层 */
.el-dialog__title {
  line-height: 18px;
  font-size: 18px;
  color: #303133;
}
.el-dialog__header {
  padding: 20px 24px 20px 32px;
  border-bottom: 2px solid #e4e6e7;
}
.el-dialog__headerbtn {
  top: 22px;
  right: 24px;
}
.el-dialog__body {
  padding: 40px 24px 0 32px;
}
.el-dialog__footer {
  padding: 0 40px 40px 40px;
}

/* 去掉数字输入框上下键 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/* 昵称 */
.nickName {
  color: #fff;
  text-align: center;
}

/* 退出 */
.out-box {
  width: 200px;
}
.out {
  margin: 10px 79px 0;
  height: 24px;
  line-height: 22px;
  width: 42px;
  display: inline-block;
  background: #f3f3f3;
  border: solid 1px #cccccc;
  color: #333;
  border-radius: 2px;
}
</style>
